var tceppsInvocationError = false;
var _ccDeferred;
var _ccModelName="#divEnterCC";

function getCreditCardForm(
	tcepps
) {
	addTceppsActionApp(tcepps);

	var form = 
		generateTceppsProxyForm(
			tcepps.action
		,	tcepps.target
		,	tcepps.method
		)
	;
	appendHiddenInputs(form, tcepps);
	
	submitTceppsProxyForm(form, false);	
}


function invokeTceppsProxy(
	tceppsProxy
) {
	
	addTceppsActionApp(tceppsProxy);
	
	var form = 
		generateTceppsProxyForm(
			tceppsProxy.action
		,	"tceppsProxyIFrame"
		,	"post"
		)
	;

	appendHiddenInputs(form, tceppsProxy);
	
	submitTceppsProxyForm(form, false);
}

function addTceppsActionApp(tcepps) {
	if (tcepps.app) {
		addTceppsActionParameter(tcepps, "tceppsApp", tcepps.app.value); //S21CSA_MYCSA
	}
}

function addTceppsActionParameter(tcepps, parameterName, parameterValue) {
	if (tcepps.action.indexOf(parameterName + "=") < 0) {
		if (tcepps.action.indexOf("?") < 0) {
			tcepps.action += "?";
		} else {
			tcepps.action += "&";
		}
		tcepps.action += (parameterName + "=" + parameterValue);
		console.log("Printing tecepps.action value: ",tcepps.action );
	}	
}

function submitCreditCardForm(queryString) {	
	this.tceppsInvocationError = false;
	if (queryString.length > 0) {
    	var parameters = parseQueryString(queryString);
    	if (top.creditCardFormIFrame.invokeTceppsServiceOperation) {
    		top.creditCardFormIFrame.invokeTceppsServiceOperation(parameters);
    	}
	}
}


function invokeTceppsResponseError(tcepps, errorMessage) {
	var form = 
		generateTceppsProxyForm(
			tcepps.proxyUrl.value + "?status=ERROR&errorMessage=" + errorMessage
		,	"tceppsProxyIFrame"
		,	"post"
		)
	;					
	submitTceppsProxyForm(form, false);	
}


function getTceppsProxyForm(
	tcepps
) {
	
	addTceppsActionApp(tcepps);
	
	var form = generateTceppsProxyForm(
		tcepps.action
	, 	"tceppsProxyIFrame"
	,	"post"
	);

	appendHiddenInput(form, "tcepps.app"					, tcepps.app.value);
	appendHiddenInput(form, "tcepps.token"					, tcepps.token.value);
	appendHiddenInput(form, "tcepps.sign"					, tcepps.sign.value);
	appendHiddenInput(form, "tcepps.serviceOperation"		, tcepps.serviceOperation.value);
	
	return form;
}

function generateTceppsProxyForm(
	action
,	target
,	method
){
	generateTceppsProxyIFrame();

	var form = document.createElement("form");
	form.action = action;
	form.target = target;
	form.method = method;
	
	return form;
}

function generateTceppsProxyIFrame() {
	if(document.getElementById("tceppsProxyIFrame")) return;
	
	var tceppsProxyIFrame = document.createElement("iframe");
	document.body.appendChild(tceppsProxyIFrame);
	tceppsProxyIFrame.id 					= "tceppsProxyIFrame";
	tceppsProxyIFrame.name 					= "tceppsProxyIFrame";
	tceppsProxyIFrame.width					= 10;
	tceppsProxyIFrame.height				= 10;	
	tceppsProxyIFrame.contentWindow.name 	= "tceppsProxyIFrame";
	tceppsProxyIFrame.style.display 		= "none";
	addOnLoadEventListener(tceppsProxyIFrame);
	return tceppsProxyIFrame;
}

function addOnLoadEventListener(tceppsProxyIFrame) {
	if (tceppsProxyIFrame.addEventListener) {
		tceppsProxyIFrame.addEventListener("load", tceppsProxyIFrameOnLoad(), true);
	} else {
		tceppsProxyIFrame.attachEvent("onload", tceppsProxyIFrameOnLoad);
	}	
}

function tceppsProxyIFrameOnLoad() {
	//document.body.removeChild(document.getElementById("tceppsProxyIFrame"));
	setTimeout(checkTceppsInvocationError, 500);
}

function checkTceppsInvocationError() {
	if (this.tceppsInvocationError) {
		if (this.tceppsResponseError) {
			this.tceppsResponseError("Failed to invoke TCEPPS Service.");
		} else {
			alert("Failed to invoke TCEPPS Service.");
		}
	}
}

function appendHiddenInputs(targetForm, sourceForm) {
	for (var i = 0; i < sourceForm.elements.length; i++) {
		var sourceFormElement = sourceForm.elements[i];
		appendHiddenInput(targetForm, sourceForm.name + "." + sourceFormElement.name, sourceFormElement.value);
	}
}

function appendHiddenInput(form, name, value) {
	var hiddenInput = document.createElement("input");
	hiddenInput.type = "hidden";
	hiddenInput.name = name;
	hiddenInput.value = value;
	form.appendChild(hiddenInput);	
}


function submitTceppsProxyForm(tceppsProxyForm, checkInvocationError) {
	this.tceppsInvocationError = checkInvocationError;
	document.body.appendChild(tceppsProxyForm);	
	tceppsProxyForm.submit();
	document.body.removeChild(tceppsProxyForm);
}

function getParentUrl() {
	var url = window.location.href;
	return url.substr(0, url.lastIndexOf("/") + 1);			
}

function tceppsResponseSuccess(parameters) {
	var operation=parameters["tcepps.serviceOperation"];
	if (operation != "cancel") {
			if (parameters["appToken"]) {
				creditCard.appToken.value = parameters["appToken"];
			}
		
			if (parameters["tceppsEncryptedResponse"]) {
				creditCard.tceppsEncryptedResponse.value = parameters["tceppsEncryptedResponse"];
			}
			if (parameters["tceppsEncryptedResponseKey"]) {
				creditCard.tceppsEncryptedResponseKey.value = parameters["tceppsEncryptedResponseKey"];
			}
			if(operation=="profileAdd"){
				//{ppa.procStatusMessage=Profile Request Processed, ppa.cardBrand=VI, ppa.ccAccountNum=1111, ppa.ccExp=201612, ppa.procStatus=0, ppa.customerRefNum=77891956}
				$.creditCard($.extend({}, {"action":"close"}, parameters));
			}else if( operation=="newOrder"){
				// {pno.respDateTime=20160921152243, pno.customerRefNum=77893072, pno.respCode=00, pno.txRefNum=57E2DE0234DD2145C9EEDD04CBA0B94F0A25541E, pno.cardBrand=VI, pno.procStatus=0, pno.procStatusMessage=Approved, pno.profileProcStatus=0, pno.avsRespCode=3 , pno.approvalStatus=1}
				$(_ccModelName)[0].innerHTML="";
				if((parameters["procStatus"]!=="0" || parameters["approvalStatus"]!=="1" ) && parameters["txRefNum"]){
					delete parameters["txRefNum"];
				}
				delete parameters["x"];
				$.creditCard($.extend({}, {"action":"received"}, parameters));
			}
		}
}

function tceppsResponseError(parameters) {
	var operation=parameters["tcepps.serviceOperation"];
	if(operation=="profileAdd"){
		$.creditCard($.extend({}, {"action":"profie_add_error"}, parameters));
	}else if( operation=="newOrder"){
		$(_ccModelName)[0].innerHTML="";
		delete parameters["x"];
		$.creditCard($.extend({}, {"action":"new_order_error"}, parameters));
	}else{
		alert("Invalid operation "+operation);
		_ccDeferred.reject(parameters);
	}
	
}

function cleanUpTceppsProxyIFrame(){
	var iframe=document.getElementById("tceppsProxyIFrame");
	while(iframe){
		document.body.removeChild(iframe);
		iframe=document.getElementById("tceppsProxyIFrame");
	}
}

function openCCDialog(title,url,height,width,top, options)
{
	$(_ccModelName).dialog({
		cache: false,
		height: 240,
		title: title, 
		top: top,
		modal: true ,
		zIndex:1005,
	    resizable: false,
		autoOpen: false,
		width: width,
		closeOnEscape: true
		,buttons: {
		"Submit": function() {
			var tceppsProxy	= document.forms["tceppsProxy"];
		   // invokeTceppsProxy(tceppsProxy);
		   let postparams =options;
		    creditCardFormIFrame.postMessage({type: "processTransaction", payload: {avsName: postparams.customerName, address1: postparams.customerAddress1, address2: '', city: postparams.customerCity, state: postparams.customerState, zipCode: postparams.customerZIP,countryCode: postparams.countryCode}}, '*');
		},
		"Close": function() {
			$(this).dialog("close");
			$(this).dialog("destroy");
			$(_ccModelName).html("");
			$(_ccModelName).trigger("closed");
		    cleanUpTceppsProxyIFrame();
		}}
	});
	
	delete options["title"];
	delete options["height"];
	delete options["width"];
     

	 console.log("Printing url before ajax call: ", url);

	$.ajax({
		url: url,
		data:options,
		cache: false,
		success: function(data) {
			console.log("Inside success");
			console.log("Data Inside success: ", data);
			$(_ccModelName).html("");
			$(_ccModelName).html(data);
			var tcepps= document.forms["tcepps"];
			console.log("Printing value of tcepps after success: ", tcepps);
			getCreditCardForm(tcepps);
		    cleanUpTceppsProxyIFrame();
	        }             
	});
    $( _ccModelName ).dialog("open")
    	.dialog("widget")
    	.find(".ui-dialog-buttonset .ui-button-text").addClass("btn").end()
    	.find(".ui-dialog-title").css({"float":"none"}).end()
    	.find(".ui-dialog-content").css({"overflow":"hidden","border":"none"});
	$(".ui-dialog-titlebar").css({"background-color":"#000","color":"#FFF"});
	$("div.ui-dialog-titlebar button").remove();
	
	$(".ui-dialog button").attr("class","btn btn-primary btn-sm");
	//$(".ui-dialog button").addClass("btn").addClass("").addClass("");
	
	$(".ui-dialog").css({"top":top,"float":"none","position":"absolute"});
	 

}

function postCCAuth(url,options)
{
	
	$.ajax({
		url: url,
		data:options,
		cache: false,
		success: function(data) {
			$(_ccModelName).html(data).hide();
			var tcepps= document.forms["tcepps"];
			getCreditCardForm(tcepps);
		    cleanUpTceppsProxyIFrame();
		    
		    setTimeout(function(){
		    	
		    	var tcepps		= document.forms["tcepps"];
		    	var tceppsProxy	= document.forms["tceppsProxy"];
		    	
		    	tcepps.serviceOperation.value = 'newOrder';
		    	tceppsProxy.serviceOperation.value = 'newOrder';
		    	invokeTceppsProxy(tceppsProxy);

		    }, 1000);
		    
	        }             
	});
	
}

  
$.creditCard = function(url,options) {  
	// If url is an object, simulate pre-1.5 signature
	console.log("Inside creditCard method tcepp.js class");

	console.log("Printing URL: ", url );
	console.log("Printing options: : ", options );
	if ( typeof url === "object" ) {
		options = url;
		url = undefined;
	}

	// Force options to be an object
	options = options || {};
	var defaults = {
		width:400,
		height:250,
		show_tcepps_error:true
	};
	
	
	if(options.paymentType == "B")
	{
		options= $.extend( {}, defaults, options, {title:"Enter Bank Account"} );
	}
	else
	{
		options= $.extend( {}, defaults, options, {title:"Enter Credit Card"} );
	}
	//alert(JSON.stringify(options));
	 
	if(options.action=="open"){
		_ccDeferred=jQuery.Deferred();
		
		$.extend(options,{"secure":window.location.protocol == "https:"});
		openCCDialog(options.title,url,options.height,options.width, options.top, options);
		return _ccDeferred.promise();
		
	}else if(options.action=="close"){
		
		$(_ccModelName).dialog("close");
		$(_ccModelName).dialog("destroy");
		$(_ccModelName).html("");
		$(_ccModelName).trigger("closed");
		cleanUpTceppsProxyIFrame();
		
		_ccDeferred.resolve(options);
		
	}else if(options.action=="auth"){
		_ccDeferred=jQuery.Deferred();
		if(options.profileId){
			$.extend(options,{"secure":window.location.protocol == "https:"});
			postCCAuth(url, options);
		}else{
			_ccDeferred.resolve({"error":"missing profileId"});
		}
		return _ccDeferred.promise();
	}else if(options.action=="received"){
		_ccDeferred.resolve(options);
	}else if(options.action=="profie_add_error"){
		$(_ccModelName).dialog("close");
		$(_ccModelName).dialog("destroy");
		$(_ccModelName).html("");
		$(_ccModelName).trigger("closed");
		cleanUpTceppsProxyIFrame();
		
		if(options.show_tcepps_error){
			var msg= "TCEPPS communication error occurred";
			msg=options.errorMessage? msg+ ": "+options.errorMessage: msg;
			alert(msg);
		}
		_ccDeferred.reject(options);
	}else if(options.action=="new_order_error"){
		cleanUpTceppsProxyIFrame();
		
		if(options.show_tcepps_error){
			var msg= "TCEPPS communication error occurred";
			msg=options.errorMessage? msg+": "+options.errorMessage: msg;
			alert(msg);
		}
		_ccDeferred.reject(options);
	}
	
}
function parseQueryString(queryString) {
	var parameters = new Array();
  	var nameValues = decodeURI(queryString).split(/&/);
  	for (var i in nameValues) {
    	var nameValue = nameValues[i].split(/=/);
      	parameters[nameValue[0]] = nameValue[1];
  	}
  	return parameters;
}
/* window.addEventListener(
	'message', 
	(event) => {
				 var eventData = "" + event.data;
		  if ( event && event.data && event.data.type === "transactionResponse" && event.origin ) {
				 let fnd = false;
			   // Do we trust the sender of this message?  (might be
			   // different from what we originally opened, for example).
			   if ( (event.origin.startsWith('https://cpgmui') ) && event.origin.indexOf('.usa.canon.com') > 0  ) {
				  fnd = true;
			   }
			   if ( event.origin.startsWith('http://localhost') ) {
				  fnd = true;
			   }
			   if (fnd) {
				  var parameters = parseQueryString(event.data.payload);
				  var status = parameters["status"]; 
				  if (status) {
						 if (status == "SUCCESS") {
									  tceppsResponseSuccess(parameters);
						 } else {
									  tceppsResponseError(decodeURIComponent(parameters["exceptionClass"] + " : " + parameters["errorMessage"]));
						 }
				  }

			   }
		  } else {
				 console.log("skip")
		  }
			 }, 
			 false);
*/
function getBaseUrl() {
	      var re = new RegExp(/^.*\//);
	      var baseUrl = re.exec(window.location.href);
	      console.log("Base Url : " + baseUrl[0]);
	     // return  baseUrl[0];
	       var  pgUrl = window.location.origin;
	        var link = pgUrl+'/myCanonApp/';
	        console.log("link : " + link);    
	        if(window.location.port == '4200')
	        	 return "http://localhost:8070/myCanonApp/";
	        else
	        	return link;
 
	  }
			 window.addEventListener(
				'message', 
				(event) => {
					var eventData = "" + event.data;
					if (event && event.data && event.data.type === "transactionResponse" && event.origin) {
						let fnd = false;
						// Do we trust the sender of this message?
						if ((event.origin.startsWith('https://cpgmui')) && event.origin.indexOf('.usa.canon.com') > 0) {
							fnd = true;
						}
						if (event.origin.startsWith('http://localhost')) {
							fnd = true;
						}
						if (fnd) {
							var parameters = parseQueryString(event.data.payload);
							var status = parameters["status"]; 
							if (status) {
								if (status === "SUCCESS") {
									var defaultUrl = getBaseUrl();
									var completeURL =defaultUrl + "servicerequest/submitJSP";
									// Call the Spring Boot controller when status is SUCCESS
									$.ajax({
									url: completeURL,
									type: "POST",
									data: {
										tceppsEncryptedResponse: parameters["tceppsEncryptedResponse"],
										tceppsEncryptedResponseKey: parameters["tceppsEncryptedResponseKey"],
										status: parameters["status"],
										exceptionClass: parameters["exceptionClass"],
										errorMessage: parameters["errorMessage"],
										"tcepps.serviceOperation": parameters["tcepps.serviceOperation"]
									},
									headers: {
										"Accept": "application/json" // Specify expected response type
									},
									success: function(data) {
										tceppsResponseSuccess(data);
									},
									error: function(xhr, status, error) {
										console.error("Error:", error);
									}
									});
								} else {
									tceppsResponseError(parameters);
									//tceppsResponseError(decodeURIComponent(parameters["exceptionClass"] + " : " + parameters["errorMessage"]));
								}
							}
						}
					} else {
						console.log("skip");
					}
				}, 
				false
			);
			




